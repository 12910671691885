.contact-outer-container {
  align-items: center;
  background-color: #313131;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: -1px;
  padding-bottom: 70px;
  width: 100%;
}

.contact-clip-path-top {
  background-color: #313131;
  clip-path: polygon(0% 50%, 0% 100%, 100% 100%);
  height: 150px;
  margin-top: -150px;
  width: 100%;
}

.contact-message {
  color: #b1b1b1;
  margin: 80px 0px 50px 0px;
  padding: 0px 60px 0px 60px;
  text-align: center;
}

.resume-button-footer {
  background-color: #383838;
  border: 2px solid #a72db9;
  border-radius: 26px;
  color: #FFF;
  font-size: 100%;
  height: 50px;
  margin-top: 50px;
  width: 150px;
}

.resume-button-footer:hover {
  background-color: #a72db9;
  color: #FFF;
  cursor: pointer;
}

.social-icons-container {
  margin-top: 50px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  
  .contact-clip-path-top {
    height: 112px;
    margin-top: -112px;
  }

}

@media only screen and (max-width: 500px) {

  .contact-clip-path-top {
    height: 74px;
    margin-top: -74px;
  }

}
