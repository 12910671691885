.gameboard-outer-container {
  align-items: center;
  display: flex;
  height: 340px;
  justify-content: space-around;
  margin: 1%;
  margin-top: 30px;
  width: 340px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
	-tap-highlight-color: rgba(0,0,0,0);
}

.center-circle {
  background-color: #313131;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: center;
  position: absolute;
  width: 110px;
  z-index: 3;
}

.turn-text-1 {
  color: #FFF;
  margin-bottom: 0px;
  margin-top: 4px;
  font-family: 'Press Start 2P', cursive;
  font-size: 90%;
}

.turn-text-2 {
  color: #FFF;
  font-family: 'Press Start 2P', cursive;
  font-size: 90%;
  margin-top: 10px;
}

.red-box,
.green-box,
.blue-box,
.yellow-box {
  height: 170px;
  width: 170px;
}

.red-box:focus:not(:focus-visible),
.green-box:focus:not(:focus-visible),
.blue-box:focus:not(:focus-visible),
.yellow-box:focus:not(:focus-visible) {
  outline: none;
}

.red-box {
  background-color: red;
  border: 3px solid #313131;
  border-top-left-radius: 200px;
  opacity: .3;
}

.red-box:hover {
  cursor: pointer;
}

.red-active,
.red-box:active {
  border: 3px solid red;
  box-shadow: 0 0 40px 20px red;
  opacity: 1;
}

.green-box {
  background-color: #00ff00;
  border: 3px solid #313131;
  border-top-right-radius: 200px;
  opacity: .3;
}

.green-box:hover {
  cursor: pointer;
}

.green-active,
.green-box:active {
  border: 3px solid #00ff00;
  box-shadow: 0 0 40px 20px #00ff00;
  opacity: 1;
}

.blue-box {
  background-color: #006bff;
  border: 3px solid #313131;
  border-bottom-right-radius: 200px;
  opacity: .3;
}

.blue-box:hover {
  cursor: pointer;
}

.blue-active,
.blue-box:active {
  border: 3px solid #006bff;
  box-shadow: 0 0 40px 20px #006bff;
  opacity: 1;
}

.yellow-box {
  background-color: yellow;
  border: 3px solid #313131;
  border-bottom-left-radius: 200px;
  opacity: .3;
}

.yellow-box:hover {
  cursor: pointer;
}

.yellow-active,
.yellow-box:active {
  border: 3px solid yellow;
  box-shadow: 0 0 40px 20px yellow;
  opacity: 1;
}

.disabled {
  pointer-events: none;
}

.top-section,
.bottom-section {
  display: flex;
  flex-direction: column;
}

.rules-outer-container {
  align-items: center;
  border: 2px dotted #FFF;
  display: flex;
  height: 340px;
  justify-content: center;
  margin-top: 10px;
  width: 340px;
}

.rules-inner-container {
  height: 90%;
  width: 90%;
}

.rules-text {
  color: #FFF;
  font-family: 'Press Start 2P', cursive;
  font-size: 90%;
  line-height: 25px;
}
