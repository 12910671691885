.header-outer-container {
  align-items: center;
  /* background: center/100% url(../../images/purple_lines.jpg); */
  background-color: #e3e0d8;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-bottom: 100px;
  position: relative;
  text-align: center;
  width: 100%;
}

.title-name {
  display: inline-block;
  /* color: #FFF; */
  font-family: 'Dancing Script', cursive;
  font-size: 600%;
  font-weight: lighter;
  margin: 120px 15px 0px 15px;
  position: relative;
  /* text-transform: uppercase; */
}

.title-industry {
  /* color: #FFF; */
  font-family: 'Jost';
  font-size: 150%;
  position: relative;
}

.social-icon-container {
  margin-top: 80px;
  position: relative;
}

.social-icon {
  height: 35px;
  margin: 0px 10px;
  width: 35px;
}

.social-icon:hover,
.down-arrow:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: transform .5s;
}

.mouse-leave {
  transform: scale(1);
  transition: transform .5s;
}

.down-arrow {
  background: center/100% url(../../images/down_arrow_icon.png);
  border: none;
  height: 35px;
  margin-top: 150px;
  position: relative;
  width: 35px;
}

@media only screen and (max-width: 950px) {

  .title-name {
    font-size: 350%;
  }

}

@media only screen and (max-width: 660px) {

  .title-name:nth-child(2) {
    margin-top: 100px;
  }

  .title-name:nth-child(3) {
    margin-top: 0px;
  }

  .title-industry {
    font-size: 120%;
    padding-top: 20px;
  }

  .down-arrow {
    margin-top: 110px;
  }

}
