.projects-outer-container {
  align-items: center;
  background-color: #ebeeee;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 100px;
  padding-top: 50px;
  width: 100%;
}

.project-container {
  background-color: #FFF;
  border-radius: 15px;
  box-shadow: 5px 10px 18px #bbbbbb;
  margin-bottom: 50px;
  padding: 20px;
  width: 80%;
}

.project-title {
  font-size: 150%;
  margin-bottom: 10px;
}

.project-techs {
  font-family: 'Muli';
}

.project-description {
  font-family: 'Muli';
  letter-spacing: .3px;
  line-height: 150%;
  margin-top: 10px;
}

.project-button {
  background-color: #FFF;
  border: 2px solid #a72db9;
  border-radius: 21px;
  color: #000;
  font-size: 80%;
  height: 40px;
  margin: 2% 2% 0% 0%;
  width: 130px;
}

.project-button:hover {
  background-color: #a72db9;
  color: #FFF;;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  
  .projects-outer-container {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 450px) {

  .projects-outer-container {
    padding-top: 35px;
    padding-bottom: 60px;
  }

  .project-container {
    margin-bottom: 35px;
    padding: 4%;
  }

  .project-button {
    margin: 10px 10px;
    padding: 0px 20px;
    width: auto;
  }

  .project-buttons-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
  }
}
