.scoreboard-outer-container {
  align-items: center;
  display: flex;
  width: 345px;
}

.game-button {
  background-color: #313131;
  border: 2px dotted #FFF;
  color: #FFF;
  font-family: 'Press Start 2P', cursive;
  font-size: 70%;
  height: auto;
  margin: 5px 0px;
  outline: none;
  padding: 4px;
  width: 150px;
}

.game-button:hover,
.game-button:focus {
  background-color: #FFF;
  border: 2px dotted #313131;
  color: #313131;
  cursor: pointer;
}

.round-display {
  color: #FFF;
  font-family: 'Press Start 2P', cursive;
  font-size: 70%;
  margin: 8px 30px 8px 20px;
}

.round-moves-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.score-board-button-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.disabled {
  pointer-events: none;
}
