.simon-game-outer-container {
  align-items: center;
  background-color: #313131;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding-bottom: 100px;
  text-align: center;
  width: 100%;
}

.simon-title {
  color: #FFF;
  font-family: 'Press Start 2P', cursive;
  font-size: 150%;
  margin-bottom: 20px;
  margin-top: 60px;
}

.simon-clip-path-bottom {
  background-color: #313131;
  clip-path: polygon(0% 0%, 100% -50%, 100% 100%);
  height: 80px;
  margin-bottom: -80px;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  
  .simon-clip-path-bottom {
    height: 60px;
    margin-bottom: -60px;
  }

}

@media only screen and (max-width: 500px) {

  .simon-clip-path-bottom {
    height: 40px;
    margin-bottom: -40px;
  }

  .simon-game-outer-container {
    padding-bottom: 80px;
  }

}
