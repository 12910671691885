.particles-outer-container {
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}

.particles {
	height: 100%;
}